<div class="modal-body">
  <div class="identy_progress_dialog_box">
    <div class="identy_container">
      <div class="identy_dialog_state" style="text-align: center;">
        <div style="color: #4e4e4e;font-size: 20px;text-align: center;" class="pg-text-value">

          <span style="margin-right: 10px;font-size: 30px"><i class="fa fa-spinner fa-spin"></i></span>
          <span style="font-size: 20px">{{message}}</span>

        </div>
      </div>
    </div>
  </div>
</div>
