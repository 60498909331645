import {Component, Input, OnInit} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap";
@Component({
  selector: "app-progress-dialog",
  templateUrl: "./progress-dialog.component.html",
  styleUrls: ["./progress-dialog.component.css"]
})
export class ProgressDialogFingersComponent implements OnInit {

  @Input() message: string;
  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {

  }

}
