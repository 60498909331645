<app-enrollment-header></app-enrollment-header>
<div id="getCurp" class="container p-3">
    <div class="panel-body">
        <form class="p-3">
            <div class="form-group mt-2">
                <label class="font-weight-bold">Nacionalidad</label>
                <input type="text" class="form-control border border-secondary rounded p-2" value="MX México" disabled>
            </div>
            <div class="form-group mt-3">
                <label for="curp" class="font-weight-bold">Número de Identificación Nacional</label>
                <input type="text" class="form-control border border-secondary rounded p-2" id="curp_enr" name="curp">
            </div>
            <div class="panel panel-default text-center ml-4 mr-4 mt-5">
                <div 
                    type="button" 
                    class="btn btn-success btn-block pt-3 pb-3 pl-5 pr-5" 
                    (click)="showData()"
                >
                    <strong>Continuar</strong>
                </div>
            </div>
        </form>
    </div>
</div>
<div id="showInfo" class="container pt-0 pl-3 pr-3" style="display: none;">
    <div class="panel-body">
        <form class="p-3">
            <div >
                <label class=" text-primary">Nombres/Given Names</label>
                <p id='name_p' disabled></p>
            </div>
            <div >
                <label class=" text-primary">Apellidos/Surname</label>
                <p id='surname_p' disabled></p>
            </div>
            <div >
                <label class=" text-primary">Fecha de Nacimiento/Date of birth</label>
                <p id='birthday_p' disabled></p>
            </div>
            <div >
                <label class=" text-primary">Sexo/Sex</label>
                <p id='sex_p' disabled></p>
            </div>
            <div >
                <label class=" text-primary">Nacionalidad/Nationality</label>
                <p id='national_p' disabled></p>
            </div>
            <div >
                <label class=" text-primary">Número de Identificación/Identity Number</label>
                <p id='curp_p' disabled></p>
            </div>

            
            <div class="panel panel-default text-center ml-4 mr-4 mt-5">
                <div 
                    type="button" 
                    class="btn btn-success btn-block pt-3 pb-3 pl-5 pr-5" 
                    [routerLink]="['/faceScan-component','crear']"
                >
                    <strong>Continuar</strong>
                </div>
            </div>
        </form>
    </div>
</div>
<footer class="footer text-center">
    <img src="../../../assets/images/brand.png" height="90">
</footer>