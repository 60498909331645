<div class="container p-3">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="panel panel-default text-center">
                
                <div class="panel-body p-2">
                    <h2 class="text-success h2"> Acceso </h2>
                </div>
            </div>
            <div class="panel panel-default text-center">
                <div class="panel-body bg-light p-2">Autenticación Laboral</div>
            </div>
        </div>
    </div>
</div>
