import { Component, NgZone, OnInit } from '@angular/core';
import {ActivatedRoute,Router} from "@angular/router";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {ajax} from "jquery";
import {environment} from "../../../environments/environment";
declare let OnBoarding: any;


@Component({
  selector: 'app-face-scan',
  templateUrl: './face-scan.component.html',
  styleUrls: ['./face-scan.component.css']
})
export class FaceScanComponent implements OnInit {
  
  incode:any;
  session:any;
  container:any;
  routeFlag = '';
  curp = '';
  userData:any;

  incodeConfig = {
    apiURL: 'https://demo-api.incodesmile.com/',
    apiKey: 'f109e1a4c43c7bb490d76a04fad863d66dc38d50'
  };

  constructor(private route: ActivatedRoute, private router:Router, private Zone: NgZone) { }

  ngOnInit(): void {
    this.routeFlag = this.route.snapshot.paramMap.get('flag');
    this.app();
  }
  
  async startIncode(){
    let incodeObj = await OnBoarding.create(this.incodeConfig);
    await incodeObj.warmup();
    return incodeObj;
  }

  async createIncodeSession(){
    return this.incode.createSession("ALL", null, { "configurationId" : "62cc8876c5ef82b6eb58ddba" });
  }

  async app(){
    try{
        this.container=<HTMLDivElement> document.getElementById("camera-container");
        this.container.innerHTML = `<p>Starting incode...</p>`;
        this.incode = await this.startIncode();
        this.container.innerHTML = `<p>Starting session...</p>`;
        this.session = await this.createIncodeSession();
        this.container.innerHTML = `<div class="card mt-5 p-6 bg-primary"><h1><strong>Session Ready</strong></h1></div>`;
        this.renderSelfieCamera();
        
    }catch (e){
        console.dir(e);
        this.container.innerHTML = `<p>Something went wrong</p>`;
        throw e;
    } 
  }

  renderFrontIDCamera = () => {
    this.incode.renderCamera("front", this.container, {
        onSuccess: this.renderBackIDCamera, 
        token: this.session,
        numberOfTries: -1,
    });
  }

  renderBackIDCamera = () => {
    this.incode.renderCamera("back", this.container, {
      onSuccess: async () => {
        this.container.innerHTML = "<p>Loading...</p>";
        await this.incode.processId({ token: this.session.token });
        this.container.innerHTML = `<div class='card mt-5 p-6 bg-success'><h1><strong>Ready ID's</strong></h1></div>`;
        this.renderSelfieCamera();
      },
      token: this.session,
      numberOfTries: -1,
    });
  }

  renderSelfieCamera = () => {
    this.incode.renderCamera("selfie", this.container, {
      onSuccess: async (response) => {
        console.log("Finished onboarding");
        this.incode.getFinishStatus( "62cc8876c5ef82b6eb58ddba", { token : this.session.token }); 
        var picture64 = response.imageBase64;
        localStorage.setItem('picture64', picture64);
        const flag2 = ['crear',encodeURIComponent(this.createBlob(picture64)),localStorage.getItem('username')];
        this.router.navigateByUrl('/sdkRunFinger-component/'+JSON.stringify(flag2));
      },
      onError: console.log('Error'),
      token: this.session,
      numberOfTries: 3,
    });
  }

  createBlob(picture64){
    const byteCharacters = atob(picture64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: 'image'});
    return URL.createObjectURL(blob!);
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
  
}