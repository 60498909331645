import {Template, WSQCompression, AppUI, AsThreshold} from "@identy/identy-finger";
import {Backend} from "./types";
import {QualityMode} from "@identy/identy-finger";

export class MenuSelection {

  public enableAS: boolean;
  public calculateNfiq: boolean;
  public missingFinger: boolean;
  public showCaptureTraining: boolean;
  public download: boolean;
  public asThresold: AsThreshold;
  public skipDeviceCheck: boolean;
  public qualityMode: QualityMode;

  public templates: Template[];
  public compression: WSQCompression;
  public uiSelect: AppUI;

  public uiLeft: string;
  public uiRight: string;
  public uiThumb: string;
  public backend: Backend;

  public showToasts: boolean;
  public slidingFingers: boolean;

  constructor() {}

}
