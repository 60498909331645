import { Injectable } from "@angular/core";
import {Subject} from "rxjs";
import {FingerDetectionMode} from "@identy/identy-finger";

@Injectable({
  providedIn: "root"
})
export class MissingFingerService {
  public fingers: FingerDetectionMode[];
  public fingerSelection: Subject<FingerDetectionMode[]>;

  constructor() {
    this.fingers = [];
    this.fingerSelection = new Subject();
    this.bind();
  }

  bind() {
    this.fingerSelection.subscribe((fingers: FingerDetectionMode[]) => {
      this.fingers = fingers;
    });
  }

}
