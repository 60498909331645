import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { BsDropdownModule, ButtonsModule, CollapseModule, TabsModule } from "ngx-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { ModalModule } from "ngx-bootstrap";
import { CommonModule } from "@angular/common";

import { FingerSelectionComponent } from "./components/finger/components/finger-selection/finger-selection.component";
import { MissingFingersComponent } from "./components/finger/components/finger-selection/missing-fingers/missing-fingers.component";
import { SdkRunFingersComponent } from "./components/finger/components/sdk-run/sdk-run.component";

import {HttpClientModule} from "@angular/common/http";
import {Route, RouterModule} from "@angular/router";
import { IndexComponent } from "./components/index/index.component";
import {ToastrModule} from "ngx-toastr";
import {MatButtonModule} from "@angular/material/button";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatRadioModule} from "@angular/material/radio";
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatDividerModule} from "@angular/material/divider";
import {MatSelectModule} from "@angular/material/select";
import {MatOptionModule} from "@angular/material/core";
import {MatTabsModule} from "@angular/material/tabs";
import { QRCodeModule } from 'angularx-qrcode';
import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';

import { FrameViewerFingersComponent } from "./components/finger/components/dialogs/frame-viewer/frame-viewer.component";
import { TransactionDialogFingersComponent } from "./components/finger/components/sdk-run/dialogs/transaction-dialog/transaction-dialog.component";
import { ProgressDialogFingersComponent } from "./components/finger/components/sdk-run/dialogs/progress-dialog/progress-dialog.component";
import { FingerViewFingersComponent } from './components/finger/components/dialogs/frame-viewer/finger-view/finger-view.component';
import { BadQualityComponent } from './components/finger/components/dialogs/debug/bad-quality/bad-quality.component';
import { SpoofDialogComponent } from './components/finger/components/dialogs/debug/spoof/spoof-dialog.component';
import { BadQualityBodyComponent } from './components/finger/components/dialogs/debug/bad-quality/bad-quality-body/bad-quality-body.component';
import { BadQualityDebugComponent } from './components/finger/components/dialogs/debug/bad-quality/bad-quality-body/bad-quality-debug/bad-quality-debug.component';
import { SpoofDialogBodyComponent } from './components/finger/components/dialogs/debug/spoof/spoof-dialog-body/spoof-dialog-body.component';
import { SpoofDialogDebugComponent } from './components/finger/components/dialogs/debug/spoof/spoof-dialog-body/spoof-dialog-debug/spoof-dialog-debug.component';
import { UserInputFingersComponent } from './components/finger/components/dialogs/user-input/user-input.component';
import { FaceScanComponent } from './components/face-scan/face-scan.component';
import { LoggedHeaderComponent } from './components//headers/logged-header/logged-header.component';
import { LoginHeaderComponent } from './components/headers/login-header/login-header.component';
import { VerificationHeaderComponent } from './components/headers/verification-header/verification-header.component';
import { EnrollmentHeaderComponent } from './components/headers/enrollment-header/enrollment-header.component';
import { EnrollmentComponent } from './components/enrollment/enrollment.component';

const routes: Route[] = [
  {
    path: "",
    redirectTo: "app",
    pathMatch: "full"
  },
  {
    path: "app",
    component: IndexComponent,
    pathMatch: "full"
  },
  {
    path: "sdkRunFinger-component/:flag2",
    component: SdkRunFingersComponent,
    pathMatch: "full"
  },
  {
    path: "faceScan-component/:flag",
    component: FaceScanComponent,
    pathMatch: "full"
  },
  {
    path: "app-enrollment",
    component: EnrollmentComponent,
    pathMatch: "full"
  }
];
LOAD_WASM().subscribe();

// @ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    FrameViewerFingersComponent,
    TransactionDialogFingersComponent,
    ProgressDialogFingersComponent,
    FingerViewFingersComponent,
    BadQualityComponent,
    SpoofDialogComponent,
    BadQualityBodyComponent,
    BadQualityDebugComponent,
    SpoofDialogBodyComponent,
    SpoofDialogDebugComponent,
    UserInputFingersComponent,
    FingerSelectionComponent,
    MissingFingersComponent,
    SdkRunFingersComponent,
    FaceScanComponent,
    LoggedHeaderComponent,
    LoginHeaderComponent,
    VerificationHeaderComponent,
    EnrollmentHeaderComponent,
    EnrollmentComponent,
  ],
    imports: [
        BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule,
        BsDropdownModule.forRoot(),
        ButtonsModule.forRoot(),
        CollapseModule.forRoot(),
        ModalModule.forRoot(),
        RouterModule.forRoot(routes, {enableTracing: false, relativeLinkResolution: 'legacy'}),
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot(),
        TabsModule.forRoot(),
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatRadioModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        MatDividerModule,
        MatSelectModule,
        MatOptionModule,
        HttpClientModule,
        MatTabsModule,
        QRCodeModule,
        NgxScannerQrcodeModule
    ],
  providers: [],
  entryComponents: [
    FrameViewerFingersComponent,
    TransactionDialogFingersComponent,
    ProgressDialogFingersComponent,
    BadQualityComponent,
    SpoofDialogComponent,
    UserInputFingersComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
