import $ from "jquery";
import "jquery-ui/ui/widgets/dialog";
import {ClientResponse, SdkOptions, Localization} from "@identy/identy-finger";

export class TransactionCompleteDialog {

  constructor(data: any, private options: SdkOptions, private localization: Localization) {
    this._dialogData = data;
  }

  private _containerElement;
  private _dialogData: ClientResponse;
  public closed: Function;
  public opened: Function;
  public retryClicked: Function;

  render() {
    this.build();
  }

  private build() {

    let icon = "user";
    if (this._dialogData.code.toString() === "200") {
      icon = "success";
    } else if (this._dialogData.code.toString() === "500") {
      icon = "error";
    }

    const std = this._containerElement  = $(`<div class="identy_dialog_box gradient-dialog">


                    <div class="identy_container" style="position: relative">

                        <div id="identy_transaction_state">
                                <div id = "trans_image" class="${icon}"></div>
                                <div id = "trans_label"></div>
                                <div id = "trans_buttons">
                                    <button class="btn btn-sm btn-primary btn-close">${this.localization.getString("FEEDBACK_BUTTON_CLOSE")}</button>
                                    <button class="btn btn-sm btn-primary btn-retry" style="display: none">${this.localization.getString("FEEDBACK_BUTTON_RETRY")}</button>
                                </div>
                        </div>

                    </div>

                </div>`);


    const width =  $(window).width();
    const height = $(window).height();

    $(std).find(".btn-close").on("click", () => {
      this._containerElement.dialog("close");
    });

    if (this._dialogData.code.toString() === "200") {

      if ( this.closed ) {
        this.closed();
      }

    } else if ( this._dialogData.code.toString() === "401" ||   this._dialogData.code.toString() === "402" || this._dialogData.code.toString() === "501") {

      $(std).find(".btn-retry").show();
      $(std).find(".btn-retry").on("click", () => {
        this._containerElement.dialog("close");

        if ( this.retryClicked ) {
          this.retryClicked();
        }
      });

    }


    $(std).find("#trans_label").html(this.localization.getString(this._dialogData.feedback_code.toString()));

    this._containerElement.dialog({
      width,
      height,
      closeOnEscape: false,
      classes: {
        "ui-dialog": "identy-finger-dialog ui-transaction-dialog"
      },
      open: () => {
        $(std).parent().find(".ui-dialog-titlebar-close").hide();
        if ( this.opened ) {
          this.opened();
        }
      },
      close: () => {
        setTimeout(() => {
          this._containerElement.dialog("destroy");
          this._containerElement.remove();
          if ( this.closed ) {
            this.closed();
          }

        }, 10);
      }
    });

  }


}
