<div class="finger_review_item" [style.background-color]="!finger.identy_quality ? 'red' : 'white'" [style.color]="!finger.identy_quality ? 'white' : 'black'">
  <div style="font-size: 13px">{{displayObject.name}}</div>
  <div style="font-size: 11px">
    <span *ngIf="finger.nfiq != 0">{{'NFIQ :' + finger.nfiq}}</span>
    <span style="margin-left: 15px" *ngIf="finger.nfiq != 0">{{'RFQ :' + finger.rfq}}</span>
    <span style="margin-left: 15px">{{'Quality :' + finger.identy_quality}}</span>
  </div>
  <ul>
    <li>
      <div class="print_item_l" (click)="showLarge(getImage(finger.templates.PNG['DEFAULT']))">
        <img [src]="_DomSanitizationService.bypassSecurityTrustUrl(getImage(finger.templates.PNG['DEFAULT']))">
      </div>
    </li>
  </ul>
</div>


