<div class="modal-header">Response</div>
<div class="modal-body">
  <div class="spoof-dialog">
    <h4>{{header}}</h4>
<!--    <app-spoof-dialog-body *ngIf="this.viewDebug" [debug]="this.viewDebug"></app-spoof-dialog-body>-->
  </div>
</div>
<div class="modal-footer" style="text-align: center">
  <button class="btn btn-md btn-primary btn-retry" (click)="onCloseClicked()">Close</button>
  <button class="btn btn-md btn-primary btn-retry" (click)="onRetryClicked()">Retry</button>
</div>
