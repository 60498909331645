<div class="modal-body" style="padding: 0 !important;height: 60vh">
    <div class="txn-dialog-container fill">
       <div class="bg-image" >
           <div class="txn-result-container">

               <div class="txn-result-item" style="z-index: 2;padding-top: 15%">
                   <div id ="identy_transaction_state_o">
                       <div id="trans_image" class="{{showIcon}}"></div>
                   </div>
               </div>
               <div class="txn-result-item" style="z-index: 2; padding-top: 15%; color: white;text-align: center;font-size: 20px;">
                   <div style="color: white" [innerHTML]="description"></div>
               </div>
           </div>
       </div>
    </div>
</div>
<div class="modal-footer" style="padding: 0px; border: none">
    <div class="txn-buttons txn-result-item" style="z-index: 2; text-align: center; width: 100%;padding: 10%">
        <button class="btn btn-md btn-primary btn-close btn-santander" *ngIf="!showRetry" (click)="onCloseClicked()">{{closeButtonlabel}}</button>
        <button class="btn btn-md btn-primary btn-retry btn-santander" *ngIf="showRetry" (click)="onRetryClicked()">{{retryButtonLabel}}</button>
    </div>
</div>
