import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-bad-quality-debug',
  templateUrl: './bad-quality-debug.component.html',
  styleUrls: ['./bad-quality-debug.component.css']
})
export class BadQualityDebugComponent implements OnInit {
  @Input() debug: any;
  constructor(public _DomSanitizationService: DomSanitizer) { }

  public fingers=[];
  ngOnInit(): void {
    const hands = ['LEFT', 'RIGHT'];
    const fingers = ['INDEX', 'MIDDLE', 'RING', 'LITTLE', 'THUMB']

    for (const hand of hands) {
      for (const finger of fingers) {
        const fObject = this.debug.items[`(${hand},${finger})`];
        this.fingers.push({
          name: hand + " " + finger,
          nfiq: fObject.nfiq,
          cropped: this.getImage(fObject.cropped),
          binarized: this.getImage(fObject.binarized),
          rfqScore: fObject.rfqScore
        })
      }
    }
  }

  public showLarge(img: any) {
    const image = new Image();
    image.src = img;
    const w = window.open("");
    w.document.write(image.outerHTML);
  }

  public getImage(image: any) {
    return 'data:image/png;base64,'+ image;
  }

}
