import {Component, Input, OnInit} from '@angular/core';
import {Localization} from "@identy/identy-finger";
import {BsModalRef} from "ngx-bootstrap";

@Component({
  selector: 'app-spoof-dialog',
  templateUrl: './spoof-dialog.component.html',
  styleUrls: ['./spoof-dialog.component.css']
})
export class SpoofDialogComponent implements OnInit {
  @Input() private debug;
  @Input() private localization: Localization

  @Input() private onclose: Function;
  @Input() private onretry: Function;

  public header: string;
  constructor(private bsModalRef: BsModalRef) {}

  public viewDebug;
  ngOnInit(): void {
    this.viewDebug = this.debug;
    this.header = this.localization.getString("FEEDBACK_RETRY");

  }

  onCloseClicked() {
    if (this.onclose) {
      this.onclose();
    }
  }

  onRetryClicked() {
    if (this.onretry) {
      this.onretry();
    }
    this.bsModalRef.hide();
  }
}
