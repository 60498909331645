import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-spoof-dialog-debug',
  templateUrl: './spoof-dialog-debug.component.html',
  styleUrls: ['./spoof-dialog-debug.component.css']
})
export class SpoofDialogDebugComponent implements OnInit {
  @Input() debug;
  constructor() { }

  ngOnInit(): void {}

}
