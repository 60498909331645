import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../modules/services/data.service'

@Component({
  selector: 'app-logged-header',
  templateUrl: './logged-header.component.html',
  styleUrls: ['./logged-header.component.css']
})
export class LoggedHeaderComponent implements OnInit {

  constructor(private dataServ: DataService) { }

  ngOnInit(): void {
  }

  logout(){
    this.dataServ.showMsnGo('Advertencia','Confirma que desea cerrar la sesión','Cerrar','/app')
  }

}
