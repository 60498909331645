import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {FaceSDK} from "@identy/identy-face";
import {FingerSDK} from "@identy/identy-finger";

if (environment.production) {
  enableProdMode();
}

// ******************************* //
// Preinitialize... //
// ******************************* //
// SDK needs to be pre-initialized,
try {
  // FaceSDK.preInitialize({
  //   URL: `${environment.url}/api/v1/models`
  // });
  FingerSDK.preInitialize({
    URL: `${environment.url}/api/v1/models`
  });
} catch (err) {
  console.error(err);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    'use strict';

    // @ts-ignore
    if (search instanceof RegExp) {
      throw TypeError('first argument must not be a RegExp');
    }
    if (start === undefined) { start = 0; }
    return this.indexOf(search, start) !== -1;
  };
}
