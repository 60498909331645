import {Component, Input, OnInit} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap";
import {DomSanitizer, SafeHtml, SafeUrl} from "@angular/platform-browser";
import {ClientResponse, Localization, SdkOptions} from "@identy/identy-finger";

@Component({
  selector: "app-transaction-dialog",
  templateUrl: "./transaction-dialog.component.html",
  styleUrls: ["./transaction-dialog.component.css"]
})
export class TransactionDialogFingersComponent implements OnInit {

  @Input() private data: ClientResponse;
  @Input() private options: SdkOptions;
  @Input() private localization: Localization;

  @Input() private onclose: Function;
  @Input() private onretry: Function;

  private height: number;

  constructor(private bsModalRef: BsModalRef, private sanitizer: DomSanitizer) {
    this.height = 0;
    this.retryCodes = [401, 402, 403, 500, 501, 604];
  }

  public hand: SafeUrl;
  public closeButtonlabel = "Close";
  public retryButtonLabel = "Retry";

  public showRetry: boolean;
  public showIcon: string;

  public description: SafeHtml;
  public retryCodes: any;

  ngOnInit() {


    this.showRetry = this.retryCodes.indexOf(this.data.code) !== -1;
    this.showIcon = "user";
    if (this.data.code.toString() === "200") {
      this.showIcon = "success";
    } else if (this.data.code.toString() === "500") {
      this.showIcon = "error";
    }

    if (!this.showRetry) {
      this.closeButtonlabel = "Close";
    }

    let message = "";
    if (this.data.code === 604) {
      message =  "Request expired";
    } else if (this.data.code === 600) {
      message = this.localization.getString("FEEDBACK_LICENCE_INVALID");
    } else if (this.data.code === 500 && this.data.feedback_code === "UPLOAD_FAILURE") {
      message = "Upload to server failed. Please try again."
    } else {
      message = this.localization.getString(this.data.feedback_code.toString());
    }
    this.description = this.sanitizer.bypassSecurityTrustHtml(message);
  }

  onCloseClicked() {
    if (this.onclose) {
      this.onclose();
    }
  }

  onRetryClicked() {
    if (this.onretry) {
      this.onretry();
    }
    this.bsModalRef.hide();
  }
}


