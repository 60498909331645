<div *ngFor="let finger of fingers" style="overflow: auto">
  <div class="finger_review_item">
    <div style="font-size: 13px">{{finger.name}}</div>
    <div style="font-size: 11px"><span *ngIf="finger.nfiq != 0">{{'NFIQ :' + finger.nfiq}}</span></div>
    <div style="font-size: 11px"><span *ngIf="finger.rfqScore != 0">{{'RFQScore :' + finger.rfqScore}}</span></div>
    <ul>
      <li *ngIf="debug">
        <div class="print_item_l" (click)="showLarge(finger.cropped)">
          <img [src]="_DomSanitizationService.bypassSecurityTrustUrl(finger.cropped)">
        </div>
      </li>
      <li *ngIf="debug">
        <div class="print_item_l" (click)="showLarge(finger.binarized)">
          <img [src]="_DomSanitizationService.bypassSecurityTrustUrl(finger.binarized)">
        </div>
      </li>
    </ul>
  </div>
</div>
