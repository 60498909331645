import {Component, Input, OnInit} from '@angular/core';
import {DataService} from "../../../../../../../modules/services/data.service";

@Component({
  selector: 'app-spoof-dialog-body',
  templateUrl: './spoof-dialog-body.component.html',
  styleUrls: ['./spoof-dialog-body.component.css']
})
export class SpoofDialogBodyComponent implements OnInit {
  @Input() debug;
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
  }

}
