import { Component, OnInit } from '@angular/core';
import {ajax} from "jquery";

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.css']
})
export class EnrollmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  showData(){
    var curp = <HTMLInputElement> document.getElementById("curp_enr");
    
    ajax({
      url: 'https://sumintrak.com/api/curpInfo',
      method: "GET",
      data: {
        "curp": curp?.value
      },
    }).done((resp) => {
      console.log(resp);
      var name_p = <HTMLInputElement> document.getElementById("name_p");
      var surname_p = <HTMLInputElement> document.getElementById("surname_p");
      var birthday_p = <HTMLInputElement> document.getElementById("birthday_p");
      var sex_p = <HTMLInputElement> document.getElementById("sex_p");
      var national_p = <HTMLInputElement> document.getElementById("national_p");
      var curp_p = <HTMLInputElement> document.getElementById("curp_p");
      var getCurp = <HTMLInputElement> document.getElementById("getCurp");
      var showInfo = <HTMLInputElement> document.getElementById("showInfo");
      curp_p.textContent = curp?.value;
      name_p.textContent = resp[0].name;
      surname_p.textContent = resp[0].surname;
      birthday_p.textContent = resp[0].birthday;
      sex_p.textContent = resp[0].sex;
      national_p.textContent = resp[0].nationality;
      getCurp.style.display='none';
      showInfo.style.display='block';
      localStorage.setItem('id_newUser', resp[0].id);
      localStorage.setItem('curp', resp[0].curp);
      localStorage.setItem('username', resp[0].name);
    }).fail(()=>{
      console.log('Error en CurpInfo')
    });
    
  }
}
