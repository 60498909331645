import {Component, OnInit} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap";
import {FingerDetectionMode} from "@identy/identy-finger";
import {MissingFingerService} from "./missing-finger.service";
import {ContextMenuHelperService} from "../../title-bar/title-context-menu/context-menu-helper.service";

@Component({
  selector: "app-missing-fingers",
  templateUrl: "./missing-fingers.component.html",
  styleUrls: ["./missing-fingers.component.css"]
})
export class MissingFingersComponent implements OnInit {

  leftFingers: Array<object>;
  rightFingers: Array<object>;

  constructor(public bsModalRef: BsModalRef, private missingFingerHelper: MissingFingerService, private contextMenuHelper: ContextMenuHelperService) {

    this.leftFingers = [
      {
        name: "Index",
        selected: false,
        image: "/assets/images/left_top1st_finger.png",
        selected_image: "/assets/images/left_top1st_finger_select.png",
        width: 137,
        height: 124,
        mode: FingerDetectionMode.LEFT_INDEX,
        id: "07"
      },
      {
        name: "Middle",
        selected: false,
        image: "/assets/images/left_top2nd_finger.png",
        selected_image: "/assets/images/left_top2nd_finger_select.png",
        width: 137,
        height: 44,
        mode: FingerDetectionMode.LEFT_MIDDLE,
        id: "08"
      },
      {
        name: "Ring",
        selected: false,
        image: "/assets/images/left_top3rd_finger.png",
        selected_image: "/assets/images/left_top3rd_finger_select.png",
        width: 137,
        height: 51,
        mode: FingerDetectionMode.LEFT_RING,
        id: "09"
      },
      {
        name: "Little",
        selected: false,
        image: "/assets/images/left_top4th_finger.png",
        selected_image: "/assets/images/left_top4th_finger_select.png",
        width: 137,
        height: 52,
        mode: FingerDetectionMode.LEFT_LITTLE,
        id: "10"
      }
    ];


    this.rightFingers = [
      {
        name: "Index",
        selected: false,
        image: "/assets/images/right_top1st_finger.png",
        selected_image: "/assets/images/right_top1st_finger_select.png",
        width: 137,
        height: 124,
        mode: FingerDetectionMode.RIGHT_INDEX,
        id: "02"
      },
      {
        name: "Middle",
        selected: false,
        image: "/assets/images/right_top2nd_finger.png",
        selected_image: "/assets/images/right_top2nd_finger_select.png",
        width: 137,
        height: 44,
        mode: FingerDetectionMode.RIGHT_MIDDLE,
        id: "03"
      },
      {
        name: "Ring",
        selected: false,
        image: "/assets/images/right_top3rd_finger.png",
        selected_image: "/assets/images/right_top3rd_finger_select.png",
        width: 137,
        height: 51,
        mode: FingerDetectionMode.RIGHT_RING,
        id: "04"
      },
      {
        name: "Little",
        selected: false,
        image: "/assets/images/right_top4th_finger.png",
        selected_image: "/assets/images/right_top4th_finger_select.png",
        width: 137,
        height: 52,
        mode: FingerDetectionMode.RIGHT_LITTLE,
        id: "05"
      }
    ];


  }

  ngOnInit() {


    this.contextMenuHelper.missingFingerSelection.subscribe((selected) => {

      if ( !selected ) {
        this.missingFingerHelper.fingerSelection.next([]);
      }
    });

  }

  clicked(finger: any) {
    finger.selected = !finger.selected;
    this.process();
  }


  process() {
    let fingers = new Array<FingerDetectionMode>();
    const lSelected: any = this.leftFingers.filter((leftFinger: any) => {
      return leftFinger.selected;
    });

    fingers = fingers.concat(lSelected.map((fs) => {
      return fs.mode;
    }));


    const rSelected: any = this.rightFingers.filter((rightFinger: any) => {
      return rightFinger.selected;
    });

    fingers = fingers.concat(rSelected.map((fs) => {
      return fs.mode;
    }));

    this.missingFingerHelper.fingerSelection.next(fingers);

  }

  save() {
    this.bsModalRef.hide();

  }

}
