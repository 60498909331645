import {Component,NgZone,OnInit} from "@angular/core";
import {FingerSelectionHelperService} from "../finger-selection/finger-selection-helper.service";
import {
  AppUI,
  FingerSDK,
  SdkOptionsType,
  TransactionMode,
} from "@identy/identy-finger";
import {ContextMenuHelperService} from "../title-bar/title-context-menu/context-menu-helper.service";
import {ajax} from "jquery";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {FrameViewerFingersComponent} from "../dialogs/frame-viewer/frame-viewer.component";
import {TransactionCompleteDialog} from "./dialog_renderer";
import {ActivatedRoute,Router} from "@angular/router";
import {TransactionDialogFingersComponent} from "./dialogs/transaction-dialog/transaction-dialog.component";
import {ZipModule} from "../../../../modules/zip-module";
import { saveAs } from "file-saver";
import {environment} from "../../../../../environments/environment";
import { ToastrService } from 'ngx-toastr';
import {first} from "rxjs/operators";
import {SpoofDialogComponent} from "../dialogs/debug/spoof/spoof-dialog.component";
import {BadQualityComponent} from "../dialogs/debug/bad-quality/bad-quality.component";
import {UserInputFingersComponent} from "../dialogs/user-input/user-input.component";
import {ProgressDialogFingersComponent} from "./dialogs/progress-dialog/progress-dialog.component";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Base64 } from "@identy/identy-face";

declare var WURFL: any;

@Component({
  selector: "app-sdk-finger-run",
  templateUrl: "./sdk-run.component.html",
  styleUrls: ["./sdk-run.component.css"]
})
export class SdkRunFingersComponent implements OnInit {
  isRunning = false;
  isLocalhost = window.location.origin.includes("localhost");
  username = this.isLocalhost ?  "localhost" : null;

  constructor(private fingerSelectionHelper: FingerSelectionHelperService,
              private contextMenuSelection: ContextMenuHelperService,
              private modalService: BsModalService,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private router:Router,
              private Zone: NgZone) {
    this.data = [];

  }

  qrData = '';
  myPicture64='';
  template = '';
  routeFlag = [];
  data: Array<any>;
  id: any;
  action: string;
  callbackURL: string;

  bsModalTxnRef: BsModalRef;
  bsModelFrmRef: BsModalRef;
  ngOnInit() {
    this.routeFlag = JSON.parse(this.route.snapshot.paramMap.get('flag2'));
    this.id          = this.route.snapshot.queryParamMap.get("sessionId");
    this.action      = this.route.snapshot.queryParamMap.get("action");
    this.callbackURL = this.route.snapshot.queryParamMap.get("callbackURL");

    if (this.action) {

      setTimeout(() => {

        if (this.action === "capture") {
          this.capture();
        } else {
          alert("Invalid action....");
        }

      }, 150);

    }

  }

  getUser() {
    return new Promise<string>((resolve) => {
      if (this.username) {
        return resolve(this.username);
      }
      console.log("User rendering");
      this.modalService.show(UserInputFingersComponent, {
        initialState: {
          onclose: (username: string) => {
            console.log(`Username: ${username}`);
            resolve(username);
          }
        },
        ignoreBackdropClick: true
      })
    });
  }

  runTransaction(transaction: TransactionMode) {
    const selection = this.contextMenuSelection.selection;
    let pdx: any;
    let ctx = this;
    const delay = millis => new Promise<void>((resolve, reject) => {
      setTimeout(_ => resolve(), millis)
    });

    const options: SdkOptionsType = {
      enableAS: selection.enableAS,
      asThreshold: selection.asThresold,
      calculateNFIQ: selection.calculateNfiq,
      requiredTemplates: selection.templates,
      wsqCompression: selection.compression,
      showCaptureTraining: selection.showCaptureTraining,
      captureTimeout: 300000,
      skipSupportCheck: selection.skipDeviceCheck,
      showOrientationDialog: true,
      uiSelect: selection.uiSelect,
      graphics: {
        FINGERS_LEFT_HAND: "/assets/images/finger_print_left.png",
        FINGER_LEFT_THUMB: "/assets/images/finger_print_right_thumb.png",
        FINGERS_RIGHT_HAND: "/assets/images/finger_print_right.png",
        FINGER_RIGHT_THUMB: "/assets/images/finger_print_right_thumb.png"
      },
      showNextHandSelection: true,
      qualityMode: selection.qualityMode,
      debug: true,
      transaction: {
        type: transaction
      },
      events: {
        onDebugFlashCaptured(size) {
          ctx.toastr.show(`Captured Size: ${size.width}x${size.height}`)
        },
        onDebugBenchmarkCompleted(device, preview) {
          if (selection.showToasts) {
            ctx.toastr.show(`Category: ${device.processor} x Score: ${device.score}`);
          }
          ctx.toastr.show(`Preview: ${preview.width}x${preview.height}`)
        },
        onQualityCheckStart: () => {
          pdx = ctx.modalService.show(ProgressDialogFingersComponent,
            {
              class: 'modal-dialog-centered',
              ignoreBackdropClick: true,
              initialState: {
                message: "Processing Quality..."
              }
            })
        },
        onNoFlashDetected() {
          alert("Browser doesn't support flash, please be in good lighting for an optimal capture");
        },
        onQualityCheckComplete: async (qinfo: Array<any>, fFailure: boolean) => {
          await delay(300);
          for (const qinfoElement of qinfo) {
            ctx.toastr.show(`Quality ${qinfoElement.name.name}: ${qinfoElement.quality.toFixed(3)}`)
          }
          if (!fFailure) {
            ctx.toastr.show(`4F Failure`);
          }
          setTimeout(() => {
            if (pdx) {
              pdx.hide();
            }
          }, 10);
        }
      },
      slidingFingers: selection.slidingFingers,
      detectionModes: this.fingerSelectionHelper.fingers.map((finger) => {
        return finger;
      }),
    };
    if (selection.uiSelect === AppUI.IMAGE) {
      options.graphics = {
        FINGERS_LEFT_HAND: selection.uiLeft,
        FINGERS_RIGHT_HAND: selection.uiRight,
        FINGER_LEFT_THUMB: selection.uiThumb,
        FINGER_RIGHT_THUMB: selection.uiThumb
      };
    }

    if (transaction === TransactionMode.VERIFY) {
      if (!options.transaction) {
        options.transaction = {};
      }
      options.transaction.id = localStorage.getItem("transactionId");
    }
    const fingerSDK = new FingerSDK(options);
    fingerSDK.onInit = () => {
      if (window.parent) {
        console.log("SDK Loaded...");
        window.parent.postMessage("IdentySDKLoaded", "*");
      }

      fingerSDK.capture()
        .then((blob: Blob) => {
          this.isRunning = false;
          return this.postData(fingerSDK, blob, transaction);
        }).catch((err) => {
         console.log(err);
        this.isRunning = false;
        if (err.constructor.name === "LocalizationMessage") {
          if (err.code !== 202 && err.code !== 501) {
            alert(err.getLocalizedString());
          } else {
            this.showTimeout(err, fingerSDK, transaction);
            this.isRunning = false;
          }
        }

      });

    };


    fingerSDK.initialize().catch((err) => {

      const m = err && err['getLocalizedString'];
      console.log(err);
      if (m) {
        if (err.code === 100  || err.code === 500) {
          alert(err.getLocalizedString());
        }

        if(err.code === 600) {
          const clientResponse = { code: err.code, feedback_code: err.message};
          this.bsModalTxnRef = this.modalService.show(TransactionDialogFingersComponent, {
            class: "modal-dialog-centered modal-sm txn-modal-dialog",
            initialState: {
              data: clientResponse,
              options: options,
              localization: fingerSDK.getLocalization(),
              onclose: () => {
                this.bsModalTxnRef.hide();
              },
              onretry: () => {
                this.capture();
              }
            }
          });
        }
      }

    });
  }

  capture() {
    if (this.isRunning) {
      return;
    }
    this.isRunning = true;
    this.runTransaction(TransactionMode.CAPTURE);
  }

  async postData(sdk: FingerSDK, capresult: Blob, action: TransactionMode) {

    const fd = new FormData();
    fd.append("file", capresult, `bdata`);

    const pd = this.modalService.show(ProgressDialogFingersComponent,
      {
        class: 'modal-dialog-centered',
        ignoreBackdropClick: true,
        initialState: {
          message: "Uploading..."
        }
      })

   return new Promise<void>(((resolve, reject) => {
      ajax({
        url: `${environment.url}/api/v1/process?ts=${new Date().getTime()}`,
        contentType: false,
        processData: false,
        method: "POST",
        dataType: "JSON",
        data: fd,
        headers: {
          "X-DEBUG": this.username
        }
      }).done((response) => {
        if(response.code!==200) {
          reject(response);
        }
        resolve(response);
      }).fail(reject);

    })).then(async (response: any) => {
      pd.hide();
      console.log(response);
      const clientResponse = {transaction_id: response.id, code: response.code, feedback_code: response.message};
      console.log("*****************************TID**********************************");
      console.log(clientResponse.transaction_id.toString());
      console.log("*****************************TID**********************************");

      /**************************Testing *****************************/
      var leftFingers=[];
      var leftIndex = response.data['(LEFT,INDEX)']['templates']['PNG']['DEFAULT'];
      leftFingers.push(leftIndex);
      var leftMiddle = response.data['(LEFT,MIDDLE)']['templates']['PNG']['DEFAULT'];
      leftFingers.push(leftMiddle);
      var leftRing = response.data['(LEFT,RING)']['templates']['PNG']['DEFAULT'];
      leftFingers.push(leftRing);
      var leftLittle = response.data['(LEFT,LITTLE)']['templates']['PNG']['DEFAULT'];
      leftFingers.push(leftLittle);
      var returnBtn = <HTMLButtonElement> document.getElementById("returnBtn");

        // localStorage.setItem('leftIndex', leftIndex);
        // localStorage.setItem('leftMiddle', leftMiddle);
        // localStorage.setItem('leftRing', leftRing);
        // localStorage.setItem('leftLittle', leftLittle);
        
      //**************Lógica para guardar huellas y foto en QR. *********************/
      //****** Esta lógica debe ser actualizada para guardar en BBDD y/o comprimir en alta densidad****/
      var qrInfo = <HTMLDivElement> document.getElementById("qrInfo");
      var fingerPrintDiv = <HTMLDivElement> document.getElementById("fingerPrintDiv");
      var leftFingersBlob=[];
      ajax({
        url: 'https://sumintrak.com/api/updateUser',
        method: "POST",
        data: {
          "id":localStorage.getItem('id_newUser'),
          "picture":localStorage.getItem('picture64'),
          "leftIndex":leftIndex,
          "leftMiddle":leftMiddle,
          "leftRing":leftRing,
          "leftLittle":leftLittle
        },
      }).done((resp) => {
        console.log(resp)
      }).fail(()=>{
        console.log('Error Guardando imagenes en BBDD')
      });

      leftFingers.forEach((elm)=>{
        const byteCharacters = atob(elm);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'image'});
        leftFingersBlob.push((URL.createObjectURL(blob!)));
      });
      const data = {
        userId: localStorage.getItem('id_newUser'),
        curp: localStorage.getItem('curp'),
        name: this.routeFlag[2],
        picture: this.routeFlag[1],
        fingerPrints: leftFingersBlob,
      };
      /*********************************************************************************************************************/ 
      this.qrData=JSON.stringify(data);
      fingerPrintDiv.style.display = "none";
      qrInfo.style.display = "block";
      returnBtn.style.display = "block";
      this.showSwAlert();
      
      /**************************************************************/
    }).catch((err) => {
      console.log(err);
      pd.hide();
      let response = err['responseJSON'] ? err['responseJSON'] : err;
      if (!response) {
        response = {code: 500, message: "UPLOAD_FAILURE"};
      }
      let message = response.message;
      if (!sdk.hasFlash()) {
        if (message === "FEEDBACK_RETRY_QUALITY") {
          message = "FEEDBACK_RETRY_QUALITY_NO_FLASH";
        }
      }
      const clientResponse = {transaction_id: -1, code: response.code, feedback_code: message};

      const code = response.code;
      let component: any = TransactionDialogFingersComponent;
      if (code == 402) {
        component = BadQualityComponent;
      } else if (code == 401) {
        component = SpoofDialogComponent;
      }
      this.bsModalTxnRef = this.modalService.show(component, {
        class: "modal-dialog-centered modal-sm txn-modal-dialog",
        initialState: {
          data: clientResponse,
          debug: response['debug'],
          localization: sdk.getLocalization(),
          onclose: () => {
            this.bsModalTxnRef.hide();
          },
          onretry: () => {
            this.modalService.onHidden.pipe(first()).subscribe(() => {
              setTimeout(() => {
                this.capture();
              }, 10);
            })
            this.bsModalTxnRef.hide();

          }
        }

      });
    });
  }

  showTimeout(err, sdk, action) {
    const clientResponse = {code: err.code, feedback_code: err.message};
    if (err.code !== 202) {
      const dialog = new TransactionCompleteDialog(clientResponse, sdk.getOptions(), sdk.localization);
      dialog.render();
      dialog.retryClicked = () => {
        this.capture();
      };
    }

  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  showSwAlert = () => {
    Swal.fire({
      position: "botton-end",
      icon: "success",
      title: "Código QR Creado",
      text: "Se ha registrado nuevo código QR con Foto y huellas del usuario",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Crear Otro QR",
      denyButtonText: "Ir a Menu Principal",
      showCloseButton: true,
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this.router.navigateByUrl('/faceScan-component/crear');
      } else if (result.isDenied) {
        await this.router.navigateByUrl('/app');
      }
    });
  }
}
