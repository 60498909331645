import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-finger-view',
  templateUrl: './finger-view.component.html',
  styleUrls: ['./finger-view.component.css']
})
export class FingerViewFingersComponent implements OnInit {

  @Input() finger;
  @Input() debug;

  public displayObject: any;
  constructor( public _DomSanitizationService: DomSanitizer) { }


  ngOnInit(): void {
    this.displayObject = {
      name:      this.finger.hand + "_" + this.finger.finger,
      nfiq:      this.finger.nfiq,
    }
    if (this.debug && this.debug.items) {
      this.displayObject['rfqScore'] = (this.debug.items)[`(${this.finger.hand},${this.finger.finger})`]['rfqScore'];
    }
  }

  public showLarge(img: any) {
    const image = new Image();
    image.src = img;
    const w = window.open("");
    w.document.write(image.outerHTML);
  }

  public getImage(image: any) {
    return 'data:image/png;base64,'+ image;
  }

}
