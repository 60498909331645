import { Injectable } from '@angular/core';
import {Localization} from "@identy/identy-finger";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {Router,ActivatedRoute} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public localization: Localization;
  constructor(private router:Router) { }

  showMsn(titleMsn,textMsn,timeMsn,txtBtn){
    Swal.fire({
      title: titleMsn,
      text: textMsn,
      timer:timeMsn,
      confirmButtonColor: '#51B35E',
      confirmButtonText: txtBtn
    });
  }

  showMsnGo(titleMsn,textMsn,txtBtn,pathConfirm){
    return Swal.fire({
      title: titleMsn,
      text: textMsn,
      showCloseButton: true,
      confirmButtonText: txtBtn,
      confirmButtonColor: '#51B35E',
      denyButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed)
        this.router.navigateByUrl(pathConfirm);
    });
  }

}
