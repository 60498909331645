import {Component, OnInit} from "@angular/core";
import {FingerDetectionMode} from "@identy/identy-finger";
import {ContextMenuHelperService} from "../title-bar/title-context-menu/context-menu-helper.service";
import {MissingFingerService} from "./missing-fingers/missing-finger.service";
import {FingerSelectionHelperService} from "./finger-selection-helper.service";

@Component({
  selector: "app-finger-selection",
  templateUrl: "./finger-selection.component.html",
  styleUrls: ["./finger-selection.component.css"]
})
export class FingerSelectionComponent implements OnInit {

  public lFingerCount = "four";
  public rFingerCount = "four";

  private leftFingers: FingerDetectionMode[];
  private rightFingers: FingerDetectionMode[];

  private leftIndexes =  ["LEFT_INDEX", "LEFT_MIDDLE", "LEFT_RING", "LEFT_LITTLE"];
  private rightIndexes = ["RIGHT_INDEX", "RIGHT_MIDDLE", "RIGHT_RING", "RIGHT_LITTLE"];


  public lFingersSelected = true;
  public rFingersSelected = false;

  public lThumbSelected = false;
  public rThumbSelected = false;

  public twoThumbsSelected = false;

  private missingFingerSelected = false;

  constructor(private contextMenuHelper: ContextMenuHelperService,
              private missingFingerHelper: MissingFingerService,
              private fingerServiceHelper: FingerSelectionHelperService) {
    this.leftFingers = [];
    this.rightFingers = [];
  }

  ngOnInit() {

    this.contextMenuHelper.missingFingerSelection.subscribe((selected) => {
      this.missingFingerSelected = selected;
      this.updateSelection();
    });

    this.missingFingerHelper.fingerSelection.subscribe((fingers) => {
      this.updateSelection();
    });


    this.updateSelection();
  }

  updateSelection() {

    setTimeout(() => {

      this.leftFingers.length = 0;
      this.rightFingers.length = 0;

      const fingers = this.missingFingerHelper.fingers;


      if ( fingers.length > 0 ) {

        this.leftFingers = this.leftFingers.concat(fingers.filter((fs: any) => {
          return this.leftIndexes.indexOf(fs.name) !== -1;
        }));

        this.rightFingers = this.rightFingers.concat(fingers.filter((fs: any) => {
          return this.rightIndexes.indexOf(fs.name) !== -1;
        }));


        if ( this.leftFingers.length === 4 || this.leftFingers.length === 0 ) {
          this.leftFingers.length = 0;
          this.leftFingers.push(FingerDetectionMode.L4F);
        }

        if ( this.rightFingers.length  === 4 || this.rightFingers.length  === 0 ) {
          this.rightFingers.length = 0;
          this.rightFingers.push(FingerDetectionMode.R4F);
        }

      } else {

        this.leftFingers.push(FingerDetectionMode.L4F);
        this.rightFingers.push(FingerDetectionMode.R4F);

      }


      let selectedFingers = [];
      if ( this.lFingersSelected ) {
        selectedFingers = selectedFingers.concat(this.leftFingers);
      }

      if ( this.rFingersSelected ) {
        selectedFingers = selectedFingers.concat(this.rightFingers);
      }

      if (this.lThumbSelected) {
        selectedFingers.push(FingerDetectionMode.LEFT_THUMB);
      }

      if (this.rThumbSelected) {
        selectedFingers.push(FingerDetectionMode.RIGHT_THUMB);
      }

      if (this.twoThumbsSelected) {
        selectedFingers.push(FingerDetectionMode.TWO_THUMBS);
      }

      this.fingerServiceHelper.fingerSelection.next(selectedFingers);

      this.lFingerCount = this.leftFingers[0] === FingerDetectionMode.L4F ? "four" : this.leftFingers.length.toString();
      this.rFingerCount = this.rightFingers[0] === FingerDetectionMode.R4F ? "four" : this.rightFingers.length.toString();


    }, 10);

  }

}
