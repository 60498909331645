import { Component, OnInit } from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.css"]
})
export class IndexComponent implements OnInit {

  public hidden: any;
  constructor(private route: ActivatedRoute) {
    this.hidden =  this.route.snapshot.queryParamMap.get("action");
  }

  ngOnInit() {}

}
