<div class="modal-header">Response</div>
<div class="modal-body">
  <div class="bad-quality-dialog">
    <h4>{{header}}</h4>
    <app-bad-quality-body *ngIf="this.viewDebug" [debug]="viewDebug"></app-bad-quality-body>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-md btn-primary btn-retry" (click)="onCloseClicked()">Close</button>
  <button class="btn btn-md btn-primary btn-retry" (click)="onRetryClicked()">Retry</button>
</div>
