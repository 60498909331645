import {Component, Input, OnInit} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap";
@Component({
  selector: "app-frame-viewer",
  templateUrl: "./frame-viewer.component.html",
  styleUrls: ["./frame-viewer.component.css"]
})
export class FrameViewerFingersComponent implements OnInit {
  @Input() response: any;
  @Input() fingers: Array<any>;
  @Input() close: Function;

  constructor(private _bsModalRef: BsModalRef) {}

  public ngOnInit(): void {
    this.state = this.response.message === "FEEDBACK_CAPTURED";
  }

  public state: boolean;
  public onCancel(): void {
    this._bsModalRef.hide();
    if (this.close) {
      this.close();
    }
  }
}
