import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-bad-quality-body',
  templateUrl: './bad-quality-body.component.html',
  styleUrls: ['./bad-quality-body.component.css']
})
export class BadQualityBodyComponent implements OnInit {
  @Input() debug;
  constructor() { }

  ngOnInit(): void {}

}
