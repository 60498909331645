<app-login-header></app-login-header>
<app-finger-selection class="d-none"></app-finger-selection>
<div id="fingerPrintDiv">
  <div class="use-case-label mt-3">Click the Preview Button</div>
  <div class="run-section">
    <div class="flex-container">
      <div class="flex-item item" style="text-align: center; padding: 0;cursor: pointer" (click)="capture()" >
        <img src="/assets/images/preview.png" height="130" width="120"/>
      </div>
    </div>
  </div>
</div>
<div id="qrInfo" style="display: none;text-align: center;padding-top: 10px;">
  <qrcode 
    [qrdata]="this.qrData"
    [elementType]="'img'"
    [errorCorrectionLevel]="'M'"
    [allowEmptyString]="true"	
  ></qrcode>
</div>
<div id="returnBtn" style="display: none;text-align: center;padding-top: 10px;">
  <button
    id="returnBtn"
    class="btn btn-success"
    style="padding:5px;margin-top:15px;height:5rem;width:20rem;cursor:pointer;font-size:20px"
    routerLink="/app"
    > Ir a Menú Principal
  </button>
</div>
<router-outlet></router-outlet>
<footer class="footer text-center">
  <img src="../../../assets/images/brand.png" height="90">
</footer>