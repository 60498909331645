<div class="modal-header">
  <h4 class="modal-title pull-left">Missing Fingers</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="flex-container" style="height: 285px">

    <div class="flex-item item">

      <div class="finger_left_container" style="padding-left: 17px">


        <ul class="finger_item_left" style="padding-left: 5px">
          <li *ngFor="let leftFinger of leftFingers">
            <img src="{{leftFinger.image}}" height="{{leftFinger.height}}" width="{{leftFinger.width}}" (click)="clicked(leftFinger)" />
          </li>
        </ul>

        <ul class="finger_item_left_select" style="padding-left: 5px">
          <li *ngFor="let leftFinger of leftFingers">
            <img src="{{leftFinger.selected_image}}" height="{{leftFinger.height}}" width="{{leftFinger.width}}" (click)="clicked(leftFinger)"  [ngStyle]="{'opacity': leftFinger.selected ? '1' : '0'}"/>
          </li>
        </ul>

      </div>

    </div>

    <div class="flex-item item">

      <div class="finger_right_container">

        <ul class="finger_item_right" style="padding-left: 5px">
          <li *ngFor="let rightFinger of rightFingers">
            <img src="{{rightFinger.image}}" height="{{rightFinger.height}}" width="{{rightFinger.width}}" (click)="clicked(rightFinger)"/>
          </li>
        </ul>

        <ul class="finger_item_right_select" style="padding-left: 5px">
          <li *ngFor="let rightFinger of rightFingers" >
            <img src="{{rightFinger.selected_image}}" height="{{rightFinger.height}}" width="{{rightFinger.width}}" (click)="clicked(rightFinger)" [ngStyle]="{'opacity': rightFinger.selected ? '1' : '0'}"/>
          </li>
        </ul>

      </div>

    </div>

  </div>


</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="save()">OK</button>
</div>
