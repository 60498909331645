<div class="modal-header">
  User-Input
</div>
<div class="modal-body">
  <div class="form-group">
    <input type="email" class="form-control" placeholder="Enter username" [(ngModel)]="user_name">
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="close()">Ok</button>
</div>
