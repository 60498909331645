<div class="alert-box">
  <div class="modal-header" [ngStyle]="{'background-color': state ? '#29f743' : '#ff003c'}">
    <div class="modal-title">
      <div>
        Upload: {{response.uploadTs / 1000}}s
      </div>
      <div style="font-size: 13px">
        Tid: {{response.id}}
      </div>
    </div>
    <button type="button" class="close" aria-label="Close" >
      <span aria-hidden="true" (click)="onCancel()">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ul class="print_container">
      <li class="print_item" *ngFor="let finger of fingers">
        <app-finger-view [finger]="finger" [debug]="response.debug"></app-finger-view>
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onCancel()">Close</button>
  </div>
</div>

<style>
  .print_item {
    display: flex;
    flex-direction: row;
  }

  .print_item_l img {
    width: 100px;
  }

  .print_item_l  {
    flex:  0 1 auto;
  }

  .print_item_r {
    flex:  1 0 auto;
    padding-left: 10px;;
  }

</style>
