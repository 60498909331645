<div class="container p-3">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="panel panel-default text-center">
                <button style="float: right;border-radius:50px; border: none;margin-top: 0;" (click)="logout()">
                    <mat-icon>logout</mat-icon>
                </button>
                <div class="panel-body p-2">
                    <h2 class="text-success h2">Consulta</h2>
                </div>
            </div>
            <div class="panel panel-default text-center">
                <div class="panel-body bg-light p-2">Operaciones</div>
            </div>
        </div>
    </div>
</div>
