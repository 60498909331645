import {Component, Input, OnInit} from '@angular/core';
import {Localization} from "@identy/identy-finger";
import {BsModalRef} from "ngx-bootstrap";

@Component({
  selector: 'app-bad-quality',
  templateUrl: './bad-quality.component.html',
  styleUrls: ['./bad-quality.component.css']
})
export class BadQualityComponent implements OnInit {
  @Input() private debug;
  @Input() private localization:Localization;

  @Input() private onclose: Function;
  @Input() private onretry: Function;
  constructor(private bsModalRef: BsModalRef) { }

  public header: string;
  public viewDebug;
  public displayObject = {}
  ngOnInit(): void {
    this.header = this.localization.getString("FEEDBACK_RETRY_QUALITY");
    this.viewDebug = this.debug;
  }

  onCloseClicked() {
    if (this.onclose) {
      this.onclose();
    }
  }

  onRetryClicked() {
    if (this.onretry) {
      this.onretry();
    }
    this.bsModalRef.hide();
  }

}
