import { Injectable } from "@angular/core";
import {FingerDetectionMode} from "@identy/identy-finger";
import {Subject} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class FingerSelectionHelperService {
  public fingers: FingerDetectionMode[];
  public readonly fingerSelection: Subject<FingerDetectionMode[]>;

  constructor() {

    this.fingerSelection = new Subject();
    this.fingerSelection.subscribe((fingers) => {
      this.fingers = fingers;
    });

  }
}
