<div class="app_finger_selection_container">
  <div class="finger_selection">
    <div class="container">

      <div class="row">
        <div class="col row-small-screen">
          <div class="btn-group">
            <label class="btn btn-primary" [class.active]="lFingersSelected" [(ngModel)]="lFingersSelected"  (click)="updateSelection()" btnCheckbox tabindex="0" role="button">Left {{lFingerCount}} fingers</label>
            <label class="btn btn-primary" [class.active]="rFingersSelected" [(ngModel)]="rFingersSelected" (click)="updateSelection()" btnCheckbox tabindex="0" role="button">Right {{rFingerCount}} fingers</label>
            <label class="btn btn-primary" [class.active]="lThumbSelected" [(ngModel)]="lThumbSelected" (click)="updateSelection()"   btnCheckbox tabindex="0" role="button">Left Thumb</label>
            <label class="btn btn-primary" [class.active]="rThumbSelected" [(ngModel)]="rThumbSelected" (click)="updateSelection()"   btnCheckbox tabindex="0" role="button">Right Thumb</label>
            <label class="btn btn-primary" [class.active]="twoThumbsSelected" [(ngModel)]="twoThumbsSelected" (click)="updateSelection()"   btnCheckbox tabindex="0" role="button">Two Thumb</label>
          </div>
        </div>
      </div>

      <div class="row row-large-screen">
        <div class="col" style="padding-left: 20px">
          <span style="padding: 5px; position: relative;top:2px">
            <input type="checkbox" id="chkL4F" [(ngModel)]="lFingersSelected" (click)="updateSelection()" checked/>
          </span>
          <span>Left {{lFingerCount}} fingers</span>
        </div>

        <div class="col" style="padding-left: 20px">
          <span style="padding: 5px; position: relative;top:2px">
            <input type="checkbox" id="chkR4F" [(ngModel)]="rFingersSelected" (click)="updateSelection()"/>
          </span>
          <span>Right {{rFingerCount}} fingers</span>
        </div>

      </div>
      <div class="row row-large-screen">
        <div class="col" style="padding-left: 20px">
          <span style="padding: 5px; position: relative;top:2px">
            <input type="checkbox" id="chkRThumb" [(ngModel)]="lThumbSelected" (click)="updateSelection()"/>
          </span>
          <span>Left Thumb</span>
        </div>
        <div class="col" style="padding-left: 20px">
          <span style="padding: 5px; position: relative;top:2px">
            <input type="checkbox" id="chkLThumb"  [(ngModel)]="rThumbSelected" (click)="updateSelection()"/>
          </span>
          <span>Right Thumb</span>
        </div>
      </div>

      <div class="row row-large-screen">
        <div class="col" style="padding-left: 20px">
          <span style="padding: 5px; position: relative;top:2px">
            <input type="checkbox" id="chkTwThumb" [(ngModel)]="twoThumbsSelected" (click)="updateSelection()"/>
          </span>
          <span>Two Thumb</span>
        </div>
      </div>
    </div>
  </div>
</div>
